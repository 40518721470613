import Head from 'next/head';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import getConfig from 'next/config';
import { useSelector } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Heading,
  Spacing,
  Text
} from 'groundkeeper-component-library';

import AuthenticationArea from 'shared/App/components/AuthenticationArea';
import Layout from 'shared/App/components/Layout';

import Globals from 'shared/App/utils/Globals';
import selectDefaultOrganization from 'shared/Redux/selectors/organization/selectDefaultOrganization';

const env = getConfig()?.publicRuntimeConfig;

export const FAQ = organization =>
  organization?.setting_portal_flavor === 'bad_schwartau'
    ? [
        {
          category: 'Allgemeines',
          questions: [
            {
              title: 'Was ist der Zweck dieses Online-Angebots?',
              text: (
                <>
                  <p>
                    Das Online-Angebot gibt Auskunft über die öffentlichen
                    Sportanlagen in Bad Schwartau. Die Einwohnenden von Bad
                    Schwartau, Vereine und sonstige Organisationen können sich
                    über Lage, Beschaffenheit und Verfügbarkeit dieser
                    informieren.
                  </p>
                  <p>
                    Darüber hinaus bietet das Online-Angebot Informationen und
                    Hilfestellungen zum Prozess der Sportanlagenvergabe. Es gibt
                    Anleitungen und Unterstützung beim Vergabeprozess.
                  </p>
                </>
              )
            },
            {
              title:
                'Sind alle verfügbaren Sportangebote von Bad Schwartau einsehbar?',
              text: (
                <>
                  <p>
                    Nein, nicht alle verfügbaren Sportangebote in Bad Schwartau
                    sind in diesem Online-Angebot einsehbar.
                  </p>
                  <p>
                    Das Online-Angebot zeigt nur eine bestimmte Auswahl von
                    Sportanlagen der Stadt Bad Schwartau. Die Anzeige beschränkt
                    sich auf diejenigen öffentlichen Sportanlagen, die der
                    Vergabe durch die Stadtverwaltung (z.B. Schulsporthallen)
                    unterliegen.
                  </p>
                  <p>
                    Des Weiteren wird die Schwimmhalle, der Dirtpark und der
                    Skatepark mit auf der Karte anzeigt. Hinweis: Nicht für alle
                    Sportanlagen ist eine Vergabe möglich.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie findet man Sportanlagen in einem Stadtteil bzw. in der Nähe?',
              text: (
                <>
                  <p>
                    Es gibt zwei Möglichkeiten, Sportanlagen räumlich oder nach
                    Filterkriterien zu suchen. Einerseits kann man über
                    &quot;Sportanlagen finden&quot; nach einem Stadtteil
                    filtern. Andererseits kann man sich über &quot;Sportanlagen
                    entdecken&quot; beliebig über die Karte von Bad Schwartau
                    bewegen und sich stadtweit die Sportanlagen rund um einen
                    bestimmten Punkt anschauen. Dabei kann man entweder den
                    aktuellen Standort verwenden oder zu einem bestimmten Punkt
                    auf der Karte navigieren, um sich die Sportanlagen in der
                    Umgebung anzeigen zu lassen.
                  </p>
                </>
              )
            },
            {
              title:
                'Was sind die zu beachtenden gesetzlichen Grundlagen und Vorschriften bei der Nutzungsüberlassung von Sportanlagen? ',
              text: (
                <>
                  <p>
                    Bei der Benutzung der städtischen Einrichtungen haben sich
                    alle Nutzer an die Benutzungs- und Entgeltordnung der Stadt
                    Bad Schwartau zu halten. Diese finden Sie hier:
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.bad-schwartau.de/media/custom/2327_5625_1.PDF?1627898514"
                    >
                      https://www.bad-schwartau.de/media/custom/2327_5625_1.PDF?1627898514
                    </a>
                  </p>
                </>
              )
            },
            {
              title:
                'Können ausschließlich Sportvereine die Bad Schwartauer Sportanlagen nutzen?',
              text: (
                <>
                  <p>
                    Die Sportanlagen können an juristische Personen (Vereine,
                    Schulen, Behörden) gemäß den gesetzlichen Grundlagen
                    vergeben werden.
                  </p>
                  <p>
                    Manche Sportanlagen stehen zu bestimmten Zeiten auch dem
                    Individualsport zur freien Verfügung (ohne Antragstellung).
                  </p>
                </>
              )
            },
            {
              title:
                'Wie kann man Kritik, Lob und Verbesserungsvorschläge zum Online-Angebot äußern?',
              text: (
                <>
                  <p>
                    Für Anmerkungen bezüglich der Verfügbarkeit von Zeiten oder
                    zu den Sportanlagen ist die Vergabestelle der zuständige
                    Ansprechpartner. Diese können Sie der Detailansicht zu jeder
                    Sportanlage entnehmen. Auch Hinweise zur technischen
                    Umsetzung kann an diese gerichtet werden. Diese wird die
                    Anmerkungen intern an die zuständigen
                    IT-Fachverantwortlichen weiterleiten.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Sportanlagen in Bad Schwartau – das Angebot',
          questions: [
            {
              title: 'Gibt es bei der Suche nach Sportanlagen Filteroptionen?',
              text: (
                <>
                  <p>
                    Ja, über &quot;Sportanlagen finden&quot; lassen sich
                    Filteroptionen verwenden, um die Sportanlagensuche auf
                    bestimmten Kriterien zu fokussieren. Dazu gehören
                    beispielsweise der Bezirk, die Kategorie (gedeckt oder
                    ungedeckt), der Anlagentyp (z.B. Großspielfeld,
                    Kleinspielfeld, Sporthalle Größe 1-4) oder die Freitextsuche
                    nach dem Namen des Standorts beziehungsweise der Anlage.
                  </p>
                  <p>
                    Bei weiterführenden Fragen zu Merkmalen von Sportanlagen
                    kann die Detailansicht einer jeden Sportanlage oder die
                    zuständige Vergabestelle konsultiert werden. Die
                    Filterkriterien für die Suche werden darüber hinaus
                    kontinuierlich ausgebaut.
                  </p>
                </>
              )
            },
            {
              title:
                'Was bedeuten die Eigenschaften „gedeckt“ und „ungedeckt“?',
              text: (
                <>
                  <p>
                    Von gedeckten Sportanlagen spricht man in Bad Schwartau
                    sportfachlich, wenn es sich um eine Halle oder überdachte
                    Sportanlage handelt.
                  </p>
                  <p>
                    Bei einer ungedeckten Sportanlage handelt es sich im
                    Wesentlichen um Sportplätze und sonstige Außenanlagen.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie erkennt man Störungen und Ausfälle einer Sportanlage?',
              text: (
                <>
                  <p>
                    Vorhandene Störungen, wie zum Beispiel Legionellen im
                    Duschwasser oder beschädigte Sportgeräte, werden durch die
                    Vergabestellen in der Detailansicht einer Sportanlage dieses
                    Online-Angebots veröffentlicht.
                  </p>
                  <p>
                    Kurzfristige Sperrungen etwa durch eine Rasenpflege oder
                    auch langfristige Sperrungen etwa durch eine Havarie oder
                    Sanierung werden mit dem geplanten Zeitfenster direkt im
                    Nutzungsplan der Sportanlage angezeigt. Während einer
                    Sperrung kann oder darf die Sportanlage nicht genutzt
                    werden. Eine Ausnahme stellen die Sperrungen vom Typ
                    “Reservierung” und “Freie Nutzung” dar. In beiden Fällen ist
                    eine Nutzung vorgesehen.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man die betriebenen Sportarten in seiner Umgebung herausfinden?',
              text: (
                <>
                  <p>
                    Eine gezielte Suche nach einer Sportart ist derzeit noch
                    nicht möglich. Es gibt jedoch eine Möglichkeit, über den
                    Nutzungsplan einer Sportanlage herauszufinden, mit welcher
                    Sportart ein Verein eine Sportfläche zu einer bestimmten
                    Zeit nutzt. Dazu muss im Nutzungsplan auf eine Nutzungszeit
                    geklickt werden, um die Details anzuzeigen.
                  </p>
                </>
              )
            },
            {
              title: 'Ist eine Umkreissuche nach Sportanlagen möglich?',
              text: (
                <>
                  <p>
                    Eine gezielte Suche nach einem beliebigen Ort in Bad
                    Schwartau herum mit Radiusangabe, Entfernungsangabe oder
                    weiteren einschränkenden Kriterien ist derzeit noch nicht
                    möglich. Gleichwohl kann man sich über &quot;Sportanlagen
                    entdecken&quot; beliebig über die geografische Karte von Bad
                    Schwartau bewegen. So können Sportanlagen stadtweit rund um
                    einen bestimmten Punkt dargestellt werden.
                  </p>
                </>
              )
            },
            {
              title:
                'Sind auch Sportanlagen aus dem umliegenden Ballungsraum im Online-Angebot enthalten?',
              text: (
                <>
                  <p>
                    Nein, der Inhalt des Online-Angebotes bezieht sich
                    ausschließlich auf Sportanlagen der Stadt Bad Schwartau.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Nutzungsplan',
          questions: [
            {
              title:
                'Was stellt der Nutzungsplan dar und wie aktuell ist dieser?',
              text: (
                <>
                  <p>
                    Nutzungspläne zeigen, an welchem Tag und zu welcher Uhrzeit
                    eine Sportanlage belegt, gesperrt oder möglicherweise noch
                    frei ist. Durch Klick auf eine Belegung oder Sperrung erhält
                    man weitere Details.
                  </p>
                  <p>
                    Sobald eine Vergabestelle ihre Belegungen gemäß den
                    Vergaberegelegungen verbindlich beschlossen und
                    veröffentlicht hat, sind diese zusammen mit den
                    eingetragenen Sperrungen im Nutzungsplan zu sehen. Sind
                    Verfahren noch in der Beantragungs-, Planungs- oder
                    Bewilligungsphase, so ist dieser Zwischenstand noch nicht im
                    Internet veröffentlicht.
                  </p>
                  <p>
                    Es ist wichtig zu beachten, dass stornierte Zeiten, also
                    Nutzungszeiten, die nicht wahrgenommen werden können und vom
                    Nutzenden wieder freigegeben worden sind, in Echtzeit im
                    Nutzungsplan sichtbar sind.
                  </p>
                  <p>
                    Ist der Nutzungsplan auf einer externen Internetquelle
                    hinterlegt und diese Quelle ist bekannt, so ist anstelle des
                    Plans der Link zu dieser externen Quelle hinterlegt.
                  </p>
                </>
              )
            },
            {
              title:
                'Wo kann man die noch freien Zeiten einer Sportanlage einsehen?',
              text: (
                <>
                  <p>
                    Der Nutzungsplan ist in die Detailansicht einer Sportanlage
                    eingebettet. Diese kann aufgerufen werden, indem eine
                    Sportanlage über &quot;Sportanlagen finden&quot;
                    beziehungsweise über &quot;Sportanlagen entdecken&quot;
                    identifiziert und dann dem entsprechenden Link gefolgt wird.
                    In der Detailansicht selbst muss zum Abschnitt
                    “Nutzungsplan” navigiert werden. Dort kann man die
                    Kalenderwoche und das Zeitfenster auswählen, für das ein
                    Informationsbedarf besteht.
                  </p>
                  <p>
                    Nutzungspläne sind grundsätzlich nur für Sportanlagen
                    hinterlegt, die derzeit auch zur Nutzungsüberlassung
                    beantragt werden können.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie kann man einen Nutzungsplan mit anderen Personen teilen?',
              text: (
                <>
                  <p>
                    Zum Teilen des Nutzungsplans einer Sportanlage, muss man die
                    Detailansicht der entsprechenden Sportanlage aufrufen und
                    dann die URL aus dem Browser kopieren. Den kopierten Link
                    kann man via E-Mail oder Messenger weiterleiten. Dies
                    funktioniert auch, wenn eine andere Woche als die aktuelle
                    ausgewählt wurde.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Sperrungen',
          questions: [
            {
              title: 'Was bedeutet eine Sperrung vom Typ „Reservierung“?',
              text: (
                <>
                  <p>
                    Reservierungen werden im System als Sperrungen abgebildet,
                    damit sie im Nutzungsplan veröffentlicht werden können. Bei
                    einer Reservierung wird eine Nutzung zwar in der Planung
                    vorgesehen, die endgültige Entscheidung durch einen Bescheid
                    steht noch aus. Die tatsächliche Nutzung hängt von
                    verschiedenen Faktoren ab. Beispielsweise ist die Nutzung
                    einer Wettkampffläche abhängig vom Fortschritt eines Vereins
                    in einem Punktspiel oder Wettbewerb.
                  </p>
                  <p>
                    In der Regel wird eine Reservierung in eine Belegung
                    umgewandelt. Über eine Reservierung entscheidet die
                    jeweilige Vergabestelle strikt nach Bedarf.
                  </p>
                  <p>
                    Ist eine Reservierung nicht länger notwendig - zum Beispiel,
                    weil der Verein aus dem Wettbewerb ausgeschieden ist - so
                    geben die Vergabestellen die reservierte Zeit wieder frei
                    und diese kann auf dem regulären Weg neu beantragt werden.
                  </p>
                </>
              )
            },
            {
              title: 'Was bedeutet eine Sperrung vom Typ „freie Nutzung“?',
              text: (
                <>
                  <p>
                    Sind öffentliche Sportanlagen in einem bestimmten Zeitraum
                    für die allgemeine Nutzung durch die gesamte Öffentlichkeit
                    vorgesehen, so werden diese mit dem Typ “Freie Nutzung”
                    gesperrt. Eine Nutzung durch einen geschlossenen
                    Personenkreis ist in dieser Zeit nicht möglich.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Beantragung von Nutzungszeiten',
          questions: [
            {
              title:
                'Wie kann man eine Nutzungszeit für eine Sportanlage beantragen?',
              text: (
                <>
                  <p>
                    Eine Sporthalle oder ein Sportplatz kann nicht über ein
                    Telefonat, eine E-Mail oder ein persönliches Gespräch vor
                    Ort beantragt werden. Bei der Sportanlagenvergabe handelt es
                    sich um einen Verwaltungsakt. Über die Vergabe von
                    Sportplätzen und Sporthallen entscheiden die Vergabestellen
                    nach Eingang eines formellen Antrags. Die Entscheidung
                    erfolgt gemäß den gesetzlichen Vorschriften, wie die
                    Sportförderrichtline und die Benutzungs- und Entgeltordnung
                    der Stadt Bad Schwartau und wird über einen amtlichen
                    Bescheid dem Antragstellenden bekannt gegeben.
                  </p>
                  <p>
                    Davon unberührt bleibt die Möglichkeit, eine informelle
                    Anfrage zur Verfügbarkeit einer Sportanlage oder mögliche
                    Nutzungsalternativen über die oben ausgeschlossenen Wege zu
                    stellen (Telefonat, E-Mail oder Gespräch). Die mögliche
                    Auskunft hat jedoch keinen verbindlichen Charakter.
                  </p>
                </>
              )
            },
            {
              title:
                'Wo findet man die Formulare (PDF/Papier) zur Beantragung einer Nutzungsüberlassung?',
              text: (
                <>
                  <p>
                    Das Antragsformular auf Nutzungsüberlassung einer
                    Sportanlage ist einheitlich. Dies betrifft sowohl den Antrag
                    auf die saisonale und jährliche Dauernutzung, als auch die
                    Feriennutzung (z.B. Trainingscamps) und die Einzelnutzung.
                    Das Antragsformular wird durch die Vergabestelle in ihrer
                    Internetpräsenz zusammen mit den jeweils gültigen
                    Datenschutzinformationen zur Verfügung gestellt. Bei
                    Interesse an einer Nutzungsüberlassung kann das
                    Antragsformular dort heruntergeladen werden. Die
                    Kontaktdaten zu der Vergabestelle sind im Online-Angebot
                    hinterlegt.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie kann man sich direkt an die zuständige Vergabestelle wenden?',
              text: (
                <>
                  <p>
                    In jeder Detailansicht zu einer Sportanlage ist die
                    zuständige Vergabestelle nebst ihrer Kontaktdaten und dem
                    Link zur Internetpräsenz der Vergabestelle aufgeführt.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann der Bearbeitungsstand eines Antrages über dieses Online-Angebot eingesehen werden?',
              text: (
                <>
                  <p>
                    Nein, das Online-Angebot gibt keine Auskunft über den
                    aktuellen Bearbeitungsstand der Sportanlagenvergabe. Es
                    werden ausschließlich amtlich getroffene Entscheidungen
                    veröffentlicht.
                  </p>
                  <p>
                    Auskünfte zum Bearbeitungsstand des eigenen Antrages kann im
                    Einzelfall die zuständige Vergabestelle geben.
                  </p>
                </>
              )
            },
            {
              title:
                'Unter welcher Voraussetzung ist ein Verein von der Entrichtung eines Entgelts für die Nutzung einer Sportfläche befreit?',
              text: (
                <>
                  <p>
                    Die Stadt Bad Schwartau möchte die Vereins- und
                    Verbandsarbeit in Bad Schwartau unterstützen. Wesentliche
                    Grundlage dafür ist die Sportförderrichtlinie der Stadt Bad
                    Schwartau. Eine der wesentlichen Förderungsmaßnahmen stellt
                    die entgeltfreie Überlassung von Sportflächen dar.
                  </p>
                  <p>
                    Eine wesentliche Voraussetzung für die Befreiung von einer
                    Entgeltzahlung ist der Status der Gemeinnützigkeit nach § 1
                    Absatz 1 der Sportförderrichtline der Stadt Bad Schwartau.
                    Über die Gemeinnützigkeit entscheidet das zuständige
                    Finanzamt.
                  </p>
                  <p>
                    Bei der Beantragung einer Sportanlage auf
                    Nutzungsüberlassung werden von der Vergabestelle die
                    Voraussetzungen für eine entgeltfreie Nutzung geprüft.
                  </p>
                </>
              )
            },
            {
              title:
                'Wozu dienen im Antragsformular die Alters-, Behinderungs- und Geschlechtsangaben bei den Teilnehmenden?',
              text: (
                <>
                  <p>
                    Einerseits dienen die Angaben dazu, den Bedarf an
                    Sanitäranlagen, Duschen, Umkleiden und barrierefreien
                    Gegebenheiten während der Sportflächennutzung abschätzen zu
                    können. Andererseits hilft es der statistischen Erfassung,
                    um ein Bild über die Alters- und Geschlechterverteilung
                    während der Nutzungszeiten zu erhalten. Aus der Statistik
                    können Politik und Verwaltung sinnvolle Annahmen für die
                    aktuelle und zukünftige Sportanlagenplanung und
                    Sportförderung ableiten.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man über dieses Online-Angebot seine Nutzungszeiten bezahlen?',
              text: (
                <>
                  <p>
                    Nein, eine Bezahlfunktion ist nicht integriert. Wenn für die
                    Nutzung ein Entgelt erhoben wird, so wird dies durch die
                    zuständige Vergabestelle in Rechnung gestellt.
                  </p>
                  <p>
                    Für weiterführende Informationen zur Entrichtung der
                    Entgelte wird empfohlen, sich direkt an die Vergabestelle zu
                    wenden.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man einen unspezifischen Antrag in die Richtung „Ich möchte gerne irgendeine Sportanlage“ stellen?',
              text: (
                <>
                  <p>
                    Nein, der Nutzungsgegenstand muss im Antrag immer konkret
                    benannt werden. Eine unspezifische Angabe wie “irgendeine
                    Einfeldsporthalle” ist derzeit im einheitlichen
                    Antragsverfahren nicht vorgesehen.
                  </p>
                  <p>
                    Deshalb ist es wichtig, im Vorfeld eines Antrags eine
                    Recherche nach freien Zeiten für den gewünschten Anlagentyp
                    über das Online-Angebot durchzuführen. Der Antrag muss mit
                    genauer Angabe des Nutzungsgegenstandes, das heißt mit
                    adressierter Vergabestelle, dem Standort, der Sportanlage
                    und gegebenenfalls unter Benennung der zu benutzenden
                    Teilfläche(n) an die jeweils zuständige Vergabestelle
                    gerichtet werden.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann ein Sammelantrag für mehrere Sportgruppen gleichzeitig gestellt werden?',
              text: (
                <>
                  <p>
                    Nein, dies ist nicht möglich. Ein Antrag muss sich auf genau
                    eine Sportanlage und eine Sportgruppe beziehen.
                  </p>
                </>
              )
            },
            {
              title:
                'Was muss bei sehr kurzfristigen Anfragen für eine Nutzung beachtet werden?',
              text: (
                <>
                  <p>
                    Die Benutzungs- und Entgeltordnung der Stadt Bad Schwartau
                    sieht bestimmte Fristen vor, in welcher die Anträge gestellt
                    werden müssen. Anträge, welche nicht fristgerecht eingehen,
                    werden abgelehnt.
                  </p>
                  <p>
                    Die Anträge sollen nach § 3 Absatz 2 der Benutzungs- und
                    Entgeltordnung der Stadt Bad Schwartau spätestens zwei
                    Wochen, bei Wochenend- und Feiertagsveranstaltungen sowie
                    bei Nutzung innerhalb der Schulferien vier Wochen vor der
                    beabsichtigten Nutzung bei der Stadt Bad Schwartau gestellt
                    werden.
                  </p>
                </>
              )
            }
          ]
        }
      ]
    : [
        {
          category: 'Allgemeines',
          questions: [
            {
              title: 'Was ist der Zweck dieses Online-Angebots?',
              text: (
                <>
                  <p>
                    Das Online-Angebot gibt Auskunft über die öffentlichen
                    Sportanlagen in Berlin. Die Einwohnenden von Berlin, Vereine
                    und sonstige Organisationen können sich über Lage,
                    Beschaffenheit und Verfügbarkeit dieser informieren.
                  </p>
                  <p>
                    Darüber hinaus bietet das Online-Angebot Informationen und
                    Hilfestellungen zum Prozess der Sportanlagenvergabe, der nun
                    berlinweit vereinheitlicht ist. Es gibt Anleitungen und
                    Unterstützung beim Vergabeprozess.{' '}
                  </p>
                  <p>
                    Mehr Informationen zu diesem Angebot gibt es auf der{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/"
                    >
                      Seite der Senatsverwaltung für Inneres und Sport
                    </a>{' '}
                    oder direkt bei den zuständigen Vergabestellen.
                  </p>
                </>
              )
            },
            {
              title:
                'Sind alle verfügbaren Sportangebote von Berlin einsehbar?',
              text: (
                <>
                  <p>
                    Nein, nicht alle verfügbaren Sportangebote in Berlin sind in
                    diesem Online-Angebot einsehbar.
                  </p>
                  <p>
                    Das Online-Angebot zeigt nur eine bestimmte Auswahl von
                    Sportanlagen der Stadt Berlin. Die Anzeige beschränkt sich
                    auf diejenigen öffentlichen Sportanlagen, die der Vergabe
                    durch die Sportämter der Bezirke (z.B. Schulsporthallen)
                    sowie der Senatsverwaltung für Inneres und Sport (z.B.
                    Olympiapark oder Sportforum) unterliegen. Anlagen in
                    privatem Eigentum, öffentliche Bäder, sportlich nutzbare
                    öffentliche Grünflächen der Straßen- und Grünflächenämter
                    (z.B. Bolzplätze auf Spielplätzen) sowie verschiedene
                    Sondersportanlagen (z.B. Schießplätze) sind noch nicht
                    enthalten. Informationen zu den Bädern im Land Berlin finden
                    Sie unter:
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlinerbaeder.de"
                    >
                      https://www.berlinerbaeder.de
                    </a>
                  </p>
                </>
              )
            },
            {
              title:
                'Wie findet man Sportanlagen in einem Bezirk bzw. in der Nähe?',
              text: (
                <>
                  <p>
                    Es gibt zwei Möglichkeiten, Sportanlagen räumlich oder nach
                    Filterkriterien zu suchen. Einerseits kann man über &quot;
                    <a target="_blank" rel="noopener noreferrer" href="/groups">
                      Sportanlagen finden
                    </a>
                    &quot; nach einem Bezirk filtern. Andererseits kann man sich
                    über &quot;
                    <a target="_blank" rel="noopener noreferrer" href="/map">
                      Sportanlagen entdecken
                    </a>
                    &quot; beliebig über die Karte von Berlin bewegen und sich
                    bezirksübergreifend die Sportanlagen rund um einen
                    bestimmten Punkt anschauen. Dabei kann man entweder den
                    aktuellen Standort verwenden oder zu einem bestimmten Punkt
                    auf der Karte navigieren, um sich die Sportanlagen in der
                    Umgebung anzeigen zu lassen. Die Bäder des Landes Berlin
                    werden - als wichtiger Bestandteil der Sportlandschaft
                    Berlins - in einem späteren Schritt in die TSSV
                    implementiert und sind daher noch nicht dort zu finden. Bis
                    dahin finden Sie Informationen zu den Bädern auf der Website
                    der Berliner Bäder unter:{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlinerbaeder.de"
                    >
                      https://www.berlinerbaeder.de
                    </a>
                  </p>
                </>
              )
            },
            {
              title:
                'Was sind die zu beachtenden gesetzlichen Grundlagen und Vorschriften bei der Nutzungsüberlassung von Sportanlagen? ',
              text: (
                <>
                  <p>
                    Alle Vergabestellen müssen sich bei der Vergabe von
                    Nutzungszeiten an die{' '}
                    <i>
                      “Ausführungsvorschriften über die Nutzung öffentlicher
                      Sportanlagen Berlins und für die Vermietung und
                      Verpachtung landeseigener Grundstücke an
                      Sportorganisationen”
                    </i>
                    , kurz Sportanlagen-Nutzungsvorschriften (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/veroeffentlichungen-formulare-rechtsvorschriften/sportfoerderung/anerkennung-der-foerderungswuerdigkeit/neufassung_span-2020.pdf"
                    >
                      SPAN
                    </a>
                    ), halten.
                  </p>
                  <p>
                    Nach dem Berliner Sportförderungsgesetz (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/veroeffentlichungen-formulare-rechtsvorschriften/sportfoerderung/anerkennung-der-foerderungswuerdigkeit/sportfoerderungsgesetz_stand-05-07-2021.pdf"
                    >
                      SportFG)
                    </a>{' '}
                    soll jedem die Möglichkeit eröffnet werden, sich
                    entsprechend seinen Fähigkeiten und Interessen im Sport nach
                    freier Entscheidung mit oder ohne organisatorische Bindung
                    zu betätigen.{' '}
                  </p>
                  <p>
                    Ein Teil der Förderung besteht darin, dass - unter anderem -
                    Sportanlagen, die im Eigentum des Landes Berlin oder einer
                    landesunmittelbaren juristischen Person des öffentlichen
                    Rechts stehen, entgeltfrei zur Nutzung an
                    Sportorganisationen mit anerkannter Sportförderung
                    überlassen werden können.{' '}
                  </p>
                  <p>
                    Sportorganisationen können gefördert werden, wenn sie durch
                    das für den Sport zuständige Mitglied des Senats als
                    förderungswürdig anerkannt sind. Mehr Informationen und
                    Formulare dazu gibt es unter folgendem{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/veroeffentlichungen-formulare-rechtsvorschriften/sportfoerderung/sportfoerderung-informationen-und-formulare-fuer-vereine-1168164.php"
                    >
                      Link
                    </a>
                    .
                  </p>
                </>
              )
            },
            {
              title:
                'Können ausschließlich Sportvereine die Berliner Sportanlagen nutzen?',
              text: (
                <>
                  <p>
                    Nein, die Nutzungsüberlassung ist nicht ausschließlich auf
                    Vereine beschränkt. Die Sportanlagen können sowohl an
                    juristische Personen (Vereine, Firmen, Schulen, Behörden)
                    als auch an natürliche Personen (Privatpersonen) gemäß den
                    gesetzlichen Grundlagen vergeben werden.
                  </p>
                  <p>
                    Des Weiteren stehen manche Sportanlagen zu bestimmten Zeiten
                    auch dem Individualsport zur freien Verfügung (ohne
                    Antragstellung).
                  </p>
                </>
              )
            },
            {
              title:
                'Wie kann man Kritik, Lob und Verbesserungsvorschläge zum Online-Angebot äußern?',
              text: (
                <>
                  <p>
                    Für Anmerkungen bezüglich der Verfügbarkeit von Zeiten oder
                    zu den Sportanlagen ist die jeweilige Vergabestelle der
                    zuständige Ansprechpartner. Diese können Sie der
                    Detailansicht zu jeder Sportanlage entnehmen. Auch Hinweise
                    zur technischen Umsetzung kann an diese gerichtet werden.
                    Diese wird die Anmerkungen intern an die zuständigen
                    IT-Fachverantwortlichen weiterleiten.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Sportanlagen in Berlin – das Angebot',
          questions: [
            {
              title: 'Gibt es bei der Suche nach Sportanlagen Filteroptionen?',
              text: (
                <>
                  <p>
                    Ja, über &quot;
                    <a target="_blank" rel="noopener noreferrer" href="/groups">
                      Sportanlagen finden
                    </a>
                    &quot; lassen sich Filteroptionen verwenden, um die
                    Sportanlagensuche auf bestimmten Kriterien zu fokussieren.
                    Dazu gehören beispielsweise der Bezirk, die Kategorie
                    (gedeckt oder ungedeckt), der Anlagentyp (z.B.
                    Großspielfeld, Kleinspielfeld, Sporthalle Größe 1-4) oder
                    die Freitextsuche nach dem Namen des Standorts
                    beziehungsweise der Anlage.
                  </p>
                  <p>
                    {' '}
                    Bei weiterführenden Fragen zu Merkmalen von Sportanlagen
                    kann die Detailansicht einer jeden Sportanlage oder die
                    zuständige Vergabestelle konsultiert werden. Die
                    Filterkriterien für die Suche werden darüber hinaus
                    kontinuierlich ausgebaut.
                  </p>
                </>
              )
            },
            {
              title:
                'Was bedeuten die Eigenschaften "gedeckt" und "ungedeckt"?',
              text: (
                <>
                  <p>
                    Von gedeckten Sportanlagen spricht man in Berlin
                    sportfachlich, wenn es sich um eine Halle oder überdachte
                    Sportanlage handelt.
                  </p>
                  <p>
                    {' '}
                    Bei einer ungedeckten Sportanlage handelt es sich im
                    Wesentlichen um Sportplätze und sonstige Außenanlagen wie
                    etwa Beachvolleyballfelder.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie erkennt man Störungen und Ausfälle einer Sportanlage?',
              text: (
                <>
                  <p>
                    Vorhandene Störungen, wie zum Beispiel Legionellen im
                    Duschwasser oder beschädigte Sportgeräte, werden durch die
                    Vergabestellen in der Detailansicht einer Sportanlage dieses
                    Online-Angebots veröffentlicht.
                  </p>
                  <p>
                    Kurzfristige Sperrungen etwa durch eine Rasenpflege oder
                    auch langfristige Sperrungen etwa durch eine Havarie oder
                    Sanierung werden mit dem geplanten Zeitfenster direkt im
                    Nutzungsplan der Sportanlage angezeigt. Während einer
                    Sperrung kann oder darf die Sportanlage nicht genutzt
                    werden. Eine Ausnahme stellen die Sperrungen vom Typ
                    “Reservierung” und “Freie Nutzung” dar. In beiden Fällen ist
                    eine Nutzung vorgesehen.
                  </p>
                </>
              )
            },
            {
              title:
                'Warum gibt es bei bestimmten Sportanlagen einen Hinweis, dass diese nicht zur Überlassung vorgesehen sind?',
              text: (
                <>
                  <p>
                    Manche Sportanlagen sind nicht für eine Nutzungsüberlassung
                    an die Allgemeinheit vorgesehen, aber werden dennoch in
                    diesem Online-Angebot aufgeführt. Dies geschieht vor dem
                    Hintergrund, die Vielfalt und Verteilung der gebauten
                    Sportanlagen in Berlin sukzessive öffentlich einsehbar zu
                    machen. Dabei kann es sich zum Beispiel um Außenanlagen von
                    Schulen handeln, die derzeit ausschließlich für den
                    Schulsport genutzt werden. Eltern können so etwa Einblicke
                    in das Sportflächenangebot der Schule ihrer Kinder erhalten.
                  </p>
                  <p>
                    {' '}
                    Derartige Anlagen weisen mit einem gesonderten Hinweis
                    darauf hin, dass sie nicht in der Vergabe sind. Oft wird
                    auch eine Begründung ausgewiesen, warum eine Anlage nicht
                    durch die Vergabestellen vergeben werden kann.
                  </p>
                </>
              )
            },
            {
              title:
                'Wo kann man spezielle Sportanlagen finden, die einem Verein gehören?',
              text: (
                <>
                  <p>
                    Einige Sportanlagen sind entweder nicht im Eigentum des
                    Landes Berlin oder werden nicht durch die Vergabestellen des
                    Landes Berlin vergeben. Eine ergänzende Übersicht von
                    Sportanlagen im Berliner Raum gibt es in der{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.lsb-berlin.de/themenwelten/infrastruktur/sportstaetten"
                    >
                      Sportstättensuche des Landessportbundes Berlin
                    </a>
                    .
                  </p>
                  <p>
                    {' '}
                    Auch kann sich ein Blick auf die Webseite des jeweiligen
                    Vereins lohnen, da diese zuweilen auch Informationen über
                    die Sportanlagen veröffentlichen, auf denen sie Sport
                    treiben.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man die betriebenen Sportarten in seiner Umgebung herausfinden?',
              text: (
                <>
                  <p>
                    Eine gezielte Suche nach einer Sportart ist derzeit noch
                    nicht möglich. Es gibt jedoch eine Möglichkeit, über den
                    Nutzungsplan einer Sportanlage herauszufinden, mit welcher
                    Sportart ein Verein eine Sportfläche zu einer bestimmten
                    Zeit nutzt. Dazu muss im Nutzungsplan auf eine Nutzungszeit
                    geklickt werden, um die Details anzuzeigen.
                  </p>
                </>
              )
            },
            {
              title: 'Ist eine Umkreissuche nach Sportanlagen möglich?',
              text: (
                <>
                  <p>
                    Eine gezielte Suche um einen beliebigen Berliner Ort herum
                    mit Radiusangabe, Entfernungsangabe oder weiteren
                    einschränkenden Kriterien ist derzeit noch nicht möglich.
                    Gleichwohl kann man sich über &quot;
                    <a target="_blank" rel="noopener noreferrer" href="/map">
                      Sportanlagen entdecken
                    </a>
                    &quot; beliebig über die geografische Karte von Berlin
                    bewegen. So können Sportanlagen bezirksübergreifend rund um
                    einen bestimmten Punkt dargestellt werden.
                  </p>
                </>
              )
            },
            {
              title:
                'Sind auch Sportanlagen aus dem umliegenden Ballungsraum im Online-Angebot enthalten?',
              text: (
                <>
                  <p>
                    Nein, der Inhalt des Online-Angebotes bezieht sich
                    ausschließlich auf Berliner Sportanlagen.
                  </p>
                  <p>
                    {' '}
                    Für Informationen zum Sportangebot des Landes Brandenburg
                    können die Online-Auftritte vom{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://lsb-brandenburg.de/sportstaetten/"
                    >
                      Landessportbund Brandenburg
                    </a>{' '}
                    sowie vom{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://mbjs.brandenburg.de/sport.html"
                    >
                      Ministerium für Bildung, Jugend und Sport
                    </a>{' '}
                    (MBJS) des Landes als mögliche Anlaufstelle herangezogen
                    werden.
                  </p>
                </>
              )
            },
            {
              title:
                'Findet man hier auch die Sport- und Bewegungsangebote von "Berlin bewegt sich"?',
              text: (
                <>
                  <p>
                    Das Landesprogramm für Bewegung wird von der
                    Senatsverwaltung für Wissenschaft, Gesundheit und Pflege
                    (SenWGP) verantwortet. Informationen zu den kostenlosen
                    Sportangeboten wie &quot;Sport im Park&quot; befinden sich
                    auf der{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/berlin-bewegt-sich/"
                    >
                      Programmseite
                    </a>
                    .
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Nutzungsplan',
          questions: [
            {
              title:
                'Warum fehlt bei manchen Sportanlagen der Abschnitt mit dem Nutzungsplan?',
              text: (
                <>
                  <p>
                    Wird der Abschnitt nicht angezeigt, so hat die
                    verantwortliche Vergabestelle die Nutzungspläne ihrer
                    Sportanlagen generell noch nicht freigegeben. Auskünfte, ob
                    und wann die Freigabe erfolgt, kann nur die zuständige
                    Vergabestelle erteilen.
                  </p>
                </>
              )
            },
            {
              title:
                'Was stellt der Nutzungsplan dar und wie aktuell ist dieser?',
              text: (
                <>
                  <p>
                    Nutzungspläne zeigen, an welchem Tag und zu welcher Uhrzeit
                    eine Sportanlage belegt, gesperrt oder möglicherweise noch
                    frei ist. Durch Klick auf eine Belegung oder Sperrung erhält
                    man weitere Details.
                  </p>
                  <p>
                    {' '}
                    Sobald eine Vergabestelle ihre Belegungen gemäß den
                    Vergaberegelegungen verbindlich beschlossen und
                    veröffentlicht hat, sind diese zusammen mit den
                    eingetragenen Sperrungen im Nutzungsplan zu sehen. Sind
                    Verfahren noch in der Beantragungs-, Planungs- oder
                    Bewilligungsphase, so ist dieser Zwischenstand noch nicht im
                    Internet veröffentlicht.
                  </p>
                  <p>
                    {' '}
                    Es ist wichtig zu beachten, dass stornierte Zeiten, also
                    Nutzungszeiten, die nicht wahrgenommen werden können und vom
                    Nutzenden wieder freigegeben worden sind, in Echtzeit im
                    Nutzungsplan sichtbar sind.
                  </p>
                  <p>
                    {' '}
                    Ist der Nutzungsplan auf einer externen Internetquelle
                    hinterlegt und diese Quelle ist bekannt, so ist anstelle des
                    Plans der Link zu dieser externen Quelle hinterlegt.
                  </p>
                </>
              )
            },
            {
              title:
                'Wo kann man die noch freien Zeiten einer Sportanlage einsehen?',
              text: (
                <>
                  <p>
                    Der Nutzungsplan ist in die Detailansicht einer Sportanlage
                    eingebettet. Diese kann aufgerufen werden, indem eine
                    Sportanlage über &quot;
                    <a target="_blank" rel="noopener noreferrer" href="/groups">
                      Sportanlagen finden
                    </a>
                    &quot; beziehungsweise über &quot;
                    <a target="_blank" rel="noopener noreferrer" href="/map">
                      Sportanlagen entdecken
                    </a>
                    &quot; identifiziert und dann dem entsprechenden Link
                    gefolgt wird. In der Detailansicht selbst muss zum Abschnitt
                    “Nutzungsplan” navigiert werden. Dort kann man die
                    Kalenderwoche und das Zeitfenster auswählen, für das ein
                    Informationsbedarf besteht.
                  </p>
                  <p>
                    {' '}
                    Nutzungspläne sind grundsätzlich nur für Sportanlagen
                    hinterlegt, die derzeit auch zur Nutzungsüberlassung
                    beantragt werden können.{' '}
                  </p>
                </>
              )
            },
            {
              title:
                'Was hat es mit dem Hinweis bei bestimmten Sportanlagen auf sich, dass es sich um einen Landes- oder Bundesstützpunkt handelt?',
              text: (
                <>
                  <p>
                    Es gibt durch die Senatsverwaltung für Inneres und Sport im
                    Einvernehmen mit dem Landessportbund Berlin (LSB) anerkannte
                    Trainingseinrichtungen der Landes- und Bundesfachverbände,
                    in denen ein qualitativ hochwertiges, vereinsübergreifendes
                    und sportartenspezifisches Training für die Athletinnen und
                    Athleten des Hochleistungssports regelmäßig und dauerhaft
                    stattfindet. Dabei soll die maximale Leistungsfähigkeit der
                    Sportlerinnen und Sportler in der leistungssportlichen
                    Entwicklung angestrebt werden. Die Einrichtungen dienen
                    damit vorrangig der sporttechnischen, sporttaktischen und
                    konditionellen Weiterbildung der
                    (Nachwuchs-)Leistungssportlerinnen und -sportler. 
                  </p>
                </>
              )
            },
            {
              title:
                'Warum sind manche Sportanlagen und Zeiten schon über lange Zeiträume vergeben?',
              text: (
                <>
                  <p>
                    Einige Sportanlagen werden an einen Sportverein
                    längerfristig mittels eines Vertrags zur Nutzung überlassen.
                    Ein solcher Vertrag sichert einem Verein - oftmals ist es
                    ein Großverein - die Nutzung einer Sportanlage für sehr
                    lange Zeiträume, oft für mehrere Jahre zu. Dies soll dem
                    Verein Planungssicherheit geben, da dieser sich um Personal
                    (z.B. Platzwart), Trainingsausrüstung und eigene
                    Baulichkeiten (z.B. Vereinsheim) sowie das Mikro-Management
                    des Trainings- und Wettkampfbetriebs kümmert. Aus Sicht der
                    Vergabestellen sind die Sportanlagen dann für diesen
                    Zeitraum bereits durchgängig &quot;belegt&quot;, weil die
                    Verantwortung für die Nutzung und die Erhaltung an den
                    Sportverein übergegangen ist.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie kann man einen Nutzungsplan mit anderen Personen teilen?',
              text: (
                <>
                  <p>
                    Zum Teilen des Nutzungsplans einer Sportanlage, muss man die
                    Detailansicht der entsprechenden Sportanlage aufrufen und
                    dann die URL aus dem Browser kopieren. Den kopierten Link
                    kann man via E-Mail oder Messenger weiterleiten. Dies
                    funktioniert auch, wenn eine andere Woche als die aktuelle
                    ausgewählt wurde.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Sperrungen',
          questions: [
            {
              title: 'Was bedeutet eine Sperrung vom Typ “Reservierung”?',
              text: (
                <>
                  <p>
                    Reservierungen werden im System als Sperrungen abgebildet,
                    damit sie im Nutzungsplan veröffentlicht werden können. Bei
                    einer Reservierung wird eine Nutzung zwar in der Planung
                    vorgesehen, die endgültige Entscheidung durch einen Bescheid
                    steht noch aus. Die tatsächliche Nutzung hängt von
                    verschiedenen Faktoren ab. Beispielsweise ist die Nutzung
                    einer Wettkampffläche abhängig vom Fortschritt eines Vereins
                    in einem Punktspiel oder Wettbewerb.
                  </p>
                  <p>
                    {' '}
                    In der Regel wird eine Reservierung in eine Belegung
                    umgewandelt. Über eine Reservierung entscheidet die
                    jeweilige Vergabestelle strikt nach Bedarf.
                  </p>
                  <p>
                    {' '}
                    Ist eine Reservierung nicht länger notwendig - zum Beispiel,
                    weil der Verein aus dem Wettbewerb ausgeschieden ist - so
                    geben die Vergabestellen die reservierte Zeit wieder frei
                    und diese kann auf dem regulären Weg neu beantragt werden.
                  </p>
                </>
              )
            },
            {
              title: 'Was bedeutet eine Sperrung vom Typ “Freie Nutzung”?',
              text: (
                <>
                  <p>
                    Sind öffentliche Sportanlagen in einem bestimmten Zeitraum
                    für die allgemeine Nutzung durch die gesamte Öffentlichkeit
                    vorgesehen, so werden diese mit dem Typ “Freie Nutzung”
                    gesperrt. Eine Nutzung durch einen geschlossenen
                    Personenkreis ist in dieser Zeit nicht möglich.
                  </p>
                </>
              )
            }
          ]
        },
        {
          category: 'Beantragung von Nutzungszeiten',
          questions: [
            {
              title:
                'Wie kann man eine Nutzungszeit für eine Sportanlage beantragen?',
              text: (
                <>
                  <p>
                    Eine Sporthalle oder ein Sportplatz kann nicht über ein
                    Telefonat, eine E-Mail oder ein persönliches Gespräch vor
                    Ort beantragt werden. Bei der Sportanlagenvergabe handelt es
                    sich um einen Verwaltungsakt. Über die Vergabe von
                    Sportplätzen und Sporthallen entscheiden die Vergabestellen
                    nach Eingang eines formellen Antrags. Die Entscheidung
                    erfolgt gemäß den gesetzlichen Vorschriften, wie das
                    Sportförderungsgesetz des Landes Berlin und die
                    Sportanlagennutzungsvorschrift und wird über einen amtlichen
                    Bescheid dem Antragstellenden bekannt gegeben.
                  </p>
                  <p>
                    {' '}
                    Davon unberührt bleibt die Möglichkeit, eine informelle
                    Anfrage zur Verfügbarkeit einer Sportanlage oder mögliche
                    Nutzungsalternativen über die oben ausgeschlossenen Wege zu
                    stellen (Telefonat, E-Mail oder Gespräch). Die mögliche
                    Auskunft hat jedoch keinen verbindlichen Charakter.
                  </p>
                </>
              )
            },
            {
              title:
                'Wo findet man die Formulare (PDF / Papier) zur Beantragung einer Nutzungsüberlassung?',
              text: (
                <>
                  <p>
                    Das Antragsformular auf Nutzungsüberlassung einer
                    Sportanlage ist berlinweit einheitlich. Dies betrifft sowohl
                    den Antrag auf die saisonale und jährliche Dauernutzung, als
                    auch die Feriennutzung (z.B. Trainingscamps) und die
                    Einzelnutzung. Das Antragsformular wird durch jede
                    Vergabestelle in ihrer Internetpräsenz zusammen mit den
                    jeweils gültigen Datenschutzinformationen zur Verfügung
                    gestellt. Bei Interesse an einer Nutzungsüberlassung kann
                    das Antragsformular dort heruntergeladen werden. Die
                    Kontaktdaten zu den Vergabestellen sind im Online-Angebot
                    hinterlegt.
                  </p>
                </>
              )
            },
            {
              title:
                'Wie kann man sich direkt an die zuständige Vergabestelle wenden?',
              text: (
                <>
                  <p>
                    In jeder Detailansicht zu einer Sportanlage ist die
                    zuständige Vergabestelle nebst ihrer Kontaktdaten und dem
                    Link zur Internetpräsenz der Vergabestelle aufgeführt.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann der Bearbeitungsstand eines Antrages über dieses Online-Angebot eingesehen werden?',
              text: (
                <>
                  <p>
                    Nein, das Online-Angebot gibt keine Auskunft über den
                    aktuellen Bearbeitungsstand der Sportanlagenvergabe. Es
                    werden ausschließlich amtlich getroffene Entscheidungen
                    veröffentlicht.
                  </p>
                  <p>
                    {' '}
                    Auskünfte zum Bearbeitungsstand des eigenen Antrages können
                    im Einzelfall die zuständigen Vergabestellen geben.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man Sportanlagen auch über dieses Online-Angebot beantragen?',
              text: (
                <>
                  <p>
                    Nein, derzeit kann über das Online-Angebot noch keine
                    Nutzungszeit beantragt werden. Grundsätzlich können alle
                    Sportanlagen wie bisher (schriftlich) bei den Vergabestellen
                    zur Nutzung beantragt werden. Die gewährten Nutzungszeiten
                    werden über die bereitgestellten Nutzungspläne in diesem
                    Online-Angebot veröffentlicht.
                  </p>
                  <p>
                    {' '}
                    Das Online-Angebot wird technisch ausgeweitet, so dass
                    künftig Anträge online über das Portal gestellt werden
                    können.
                  </p>
                </>
              )
            },
            {
              title:
                'Unter welcher Voraussetzung ist ein Verein von der Entrichtung eines Entgelts für die sportliche Nutzung einer Sportfläche befreit?',
              text: (
                <>
                  <p>
                    Das Land Berlin möchte den Sportstandort Berlin stärken und
                    die damit verbundene sportliche Vereins- und Verbandsarbeit
                    unterstützen. Wesentliche Grundlage dafür ist das
                    Sportförderungsgesetz (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/veroeffentlichungen-formulare-rechtsvorschriften/sportfoerderung/anerkennung-der-foerderungswuerdigkeit/sportfoerderungsgesetz_stand-05-07-2021.pdf?ts=1689065374"
                    >
                      SportFG
                    </a>
                    ). Eine der wesentlichen Förderungsmaßnahmen stellt die
                    entgeltfreie Überlassung von Sportflächen dar.
                  </p>
                  <p>
                    {' '}
                    Eine wesentliche Voraussetzung für die Befreiung von einer
                    Entgeltzahlung ist der Status der Gemeinnützigkeit und die{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/veroeffentlichungen-formulare-rechtsvorschriften/sportfoerderung/sportfoerderung-informationen-und-formulare-fuer-vereine-1168164.php"
                    >
                      Sportförderungswürdigkeit
                    </a>{' '}
                    nach §3 Absatz 1 SportFG. Während über den ersten Status das
                    zuständige Finanzamt entscheidet, kann der letztgenannte
                    Status beim für Sport zuständigen Mitglied des Senats{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlin.de/sen/inneres/sportmetropole-berlin/veroeffentlichungen-formulare-rechtsvorschriften/sportfoerderung/anerkennung-der-foerderungswuerdigkeit/210826_antragsformular-foerderungswuerdigkeit.pdf?ts=1689065375"
                    >
                      beantragt
                    </a>{' '}
                    werden.
                  </p>
                  <p>
                    {' '}
                    Bei der Beantragung einer Sportanlage auf
                    Nutzungsüberlassung werden von den Vergabestellen die
                    Voraussetzungen für eine entgeltfreie Nutzung geprüft.
                  </p>
                </>
              )
            },
            {
              title:
                'Wo kann man Berliner Wasserflächen in Hallen- oder Sommerbädern zur Nutzung beantragen?',
              text: (
                <>
                  <p>
                    Viele der Berliner Hallenbäder und Sommerbäder werden durch
                    die Berliner Bäder Betriebe (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlinerbaeder.de/"
                    >
                      BBB
                    </a>
                    ) betrieben. Die Wasserzeitenplanung obliegt somit den
                    Berliner Bäderbetrieben. Die Überlassung erfolgt vor allem
                    für Schulen zur Durchführung des Schwimmunterrichts oder an
                    Sportorganisationen mit schwimm- und wassersportlichen
                    Übungs-, Lehr- oder Wettkampfbetrieb.
                  </p>
                  <p>
                    Der Antrag zur Nutzung von Wasserflächen durch Schulen,
                    Sportorganisationen sowie entgeltpflichtige Nutzergruppen
                    wird im Bereich &quot;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.berlinerbaeder.de/service/antraege-downloads/"
                    >
                      Downloads
                    </a>
                    &quot; der BBB bereitgestellt.
                  </p>
                </>
              )
            },
            {
              title:
                'Wozu dienen im Antragsformular die Alters-, Behinderungs- und Geschlechtsangaben bei den Teilnehmenden?',
              text: (
                <>
                  <p>
                    Einerseits dienen die Angaben dazu, den Bedarf an
                    Sanitäranlagen, Duschen, Umkleiden und barrierefreien
                    Gegebenheiten während der Sportflächennutzung abschätzen zu
                    können. Andererseits hilft es der statistischen Erfassung,
                    um ein Bild über die Alters- und Geschlechterverteilung
                    während der Nutzungszeiten zu erhalten. Aus der Statistik
                    können Politik und Verwaltung sinnvolle Annahmen für die
                    aktuelle und zukünftige Sportanlagenplanung und
                    Sportförderung ableiten.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man über dieses Online-Angebot seine Nutzungszeiten bezahlen?',
              text: (
                <>
                  <p>
                    Nein, eine Bezahlfunktion ist nicht integriert. Wenn für die
                    Nutzung ein Entgelt erhoben wird, so wird dies durch die
                    zuständige Vergabestelle in Rechnung gestellt.
                  </p>
                  <p>
                    Für weiterführende Informationen zur Entrichtung der
                    Entgelte wird empfohlen, sich direkt an die jeweilige
                    Vergabestelle zu wenden.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man einen unspezifischen Antrag in die Richtung “Ich möchte gerne irgendeine Sportanlage” stellen?',
              text: (
                <>
                  <p>
                    Nein, der Nutzungsgegenstand muss im Antrag immer konkret
                    benannt werden. Eine unspezifische Angabe wie “irgendeine
                    Einfeldsporthalle” ist derzeit im einheitlichen
                    Antragsverfahren nicht vorgesehen.
                  </p>
                  <p>
                    {' '}
                    Deshalb ist es wichtig, im Vorfeld eines Antrags eine
                    Recherche nach freien Zeiten für den gewünschten Anlagentyp
                    über das Online-Angebot durchzuführen. Der Antrag muss mit
                    genauer Angabe des Nutzungsgegenstandes, das heißt mit
                    adressierter Vergabestelle, dem Standort, der Sportanlage
                    und gegebenenfalls unter Benennung der zu benutzenden
                    Teilfläche(n) an die jeweils zuständige Vergabestelle
                    gerichtet werden.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann man einen Sammelantrag für mehrere Bezirke gleichzeitig stellen?',
              text: (
                <>
                  <p>
                    Nein, die Vergabestellen sind eigene Verwaltungseinheiten
                    und sind ausschließlich für die Sportanlagen ihres Bezirkes
                    für die Vergabe zuständig und ermächtigt. Demnach ist pro
                    Bezirk beziehungsweise Vergabestelle ein eigener
                    Nutzungsantrag notwendig.
                  </p>
                  <p>
                    {' '}
                    Deshalb ist es wichtig, im Vorfeld eines Antrags eine
                    Recherche nach freien Zeiten für den gewünschten Anlagentyp
                    über das Online-Angebot durchzuführen. Der Antrag muss mit
                    genauer Angabe des Nutzungsgegenstandes, das heißt mit
                    adressierter Vergabestelle, dem Standort, der Sportanlage
                    und gegebenenfalls unter Benennung der zu benutzenden
                    Teilfläche(n) an die jeweils zuständige Vergabestelle
                    gerichtet werden.
                  </p>
                </>
              )
            },
            {
              title:
                'Kann ein Sammelantrag für mehrere Sportgruppen gleichzeitig gestellt werden?',
              text: (
                <>
                  <p>
                    Nein, dies ist nicht möglich. Ein Antrag muss sich auf genau
                    eine Sportanlage und eine Sportgruppe beziehen.{' '}
                  </p>
                </>
              )
            },
            {
              title:
                'Was muss bei sehr kurzfristigen Anfragen für eine Nutzung beachtet werden?',
              text: (
                <>
                  <p>
                    Bevor ein kurzfristiger Antrag auf Nutzungsüberlassung
                    gestellt wird, sollte eine informelle Anfrage via E-Mail
                    oder telefonisch an die zuständige Vergabestelle gestellt
                    werden, um die Chancen zu erhöhen, dass dem Antrag
                    stattgegeben wird. Dabei gibt es einige wichtige Punkte zu
                    beachten:
                  </p>
                  <p>
                    <strong>Vorlaufzeit:</strong> Eine Anfrage sollte
                    idealerweise immer mit einem Vorlauf von mindestens 14 Tagen
                    gestellt werden. Dadurch haben die Vergabestellen
                    ausreichend Zeit, die Anfrage inhaltlich zu prüfen,
                    eventuelle Probleme zu identifizieren und geeignete Lösungen
                    oder Alternativen zu finden.
                  </p>
                  <p>
                    <strong>Verfügbarkeit der Vergabestelle:</strong> Es kann
                    vorkommen, dass die Vergabestelle aufgrund von Abwesenheiten
                    (z.B. Außentermin) oder anderen Umständen nicht in der Lage
                    ist, kurzfristige Anfragen zeitnah zu bearbeiten. Es sollte
                    zudem beachtet werden, dass die Vergabestelle nicht rund um
                    die Uhr besetzt ist und Anfragen in der Regel nur zu den
                    regulären Öffnungszeiten bearbeitet werden können.
                  </p>
                  <p>
                    <strong>Zusätzliche Anforderungen:</strong> Bei manchen
                    Nutzungsanfragen sind möglicherweise zusätzliche Maßnahmen
                    erforderlich, wie beispielsweise eine persönliche Einweisung
                    in die Schließ- oder Alarmanlage oder das Vorhandensein
                    eines Platzwartes vor Ort. Diese Maßnahmen können nicht
                    immer kurzfristig organisiert werden und erfordern eine
                    entsprechende Vorlaufzeit.
                  </p>
                  <p>
                    <strong>Regenerationszeiten:</strong> Wenn es sich um eine
                    Nutzung im Freien handelt, wie zum Beispiel die Nutzung
                    eines Naturrasens, kann es erforderlich werden, eine
                    Regenerationszeit einzuhalten. Dies ist besonders wichtig,
                    um sicherzustellen, dass die Fläche in einem guten Zustand
                    bleibt und nach vorherigen Veranstaltungen ausreichend Zeit
                    hat, sich zu erholen. Solch eine Regenerationszeit kann
                    kurzfristig auf den noch frei erscheinenden Termin fallen,
                    der angefragt wird.
                  </p>
                  <p>
                    <strong>Flexibilität und Alternativen:</strong> Falls die
                    kurzfristige Anfrage aus den genannten Gründen nicht
                    umgesetzt werden kann, sollte man flexibel sein und sich
                    nach möglichen Alternativen erkundigen. Möglicherweise
                    können andere Termine oder alternative Räumlichkeiten in
                    Betracht gezogen werden, um den Bedürfnissen gerecht zu
                    werden.
                  </p>
                  <p>
                    {' '}
                    Es wird daher generell empfohlen, Anfragen für eine
                    kurzfristige Nutzung mit ausreichend Vorlaufzeit von 14
                    Tagen zu stellen, um die Chancen auf eine positive
                    Bearbeitung zu erhöhen und mögliche Hindernisse zu umgehen.
                    Die Vergabestelle wird ihr Bestes tun, um den Anfragenden
                    entgegenzukommen. Jedoch können nicht alle Wünsche aufgrund
                    der oben genannten Faktoren kurzfristig umgesetzt werden.
                  </p>
                </>
              )
            }
          ]
        }
      ];

function Faq() {
  const intl = useIntl();
  const organization = useSelector(selectDefaultOrganization);

  return (
    <>
      <Head>
        <title>{`${Globals.settings.titlePrefix}${intl.formatMessage({
          id: 'sports_portal',
          defaultMessage: 'Sports portal Berlin'
        })}${Globals.settings.titlePostfix(organization)}`}</title>
      </Head>
      <AuthenticationArea />
      <Spacing y={5} />
      <Heading type="h1">
        {intl.formatMessage({
          id: 'faq',
          defaultMessage: 'Frequently asked Questions (FAQ)'
        })}
      </Heading>
      <Spacing y={4} />
      <section>
        {FAQ(organization).map(c => {
          return (
            <React.Fragment key={c.category}>
              <Heading type="h2">{c.category}</Heading>
              {c.questions.map(q => {
                const id = encodeURIComponent(q.title);

                return (
                  <Accordion key={id}>
                    <AccordionSummary aria-controls={id} id={id}>
                      <Text>{q.title}</Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Text>{q.text}</Text>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
              <Spacing y={4} />
            </React.Fragment>
          );
        })}
      </section>
    </>
  );
}

Faq.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Faq;
